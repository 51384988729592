<template>
	<ul class="contacts__list" itemscope itemtype="http://schema.org/Organization">
		<li class="contacts__item" v-for="(item, index) in data" :key="index"
				itemprop="contactPoint" itemtype="http://schema.org/ContactPoint" itemscope
		>
			<h3 itemprop="name" class="main__sub-title">{{ item.title }}</h3>
			<div class="contacts__box">
				<ul class="contacts__sub-list">
					<li
						class="contacts__sub-item"
						v-for="(employee, index) in item.list"
						:key="index"
					>
						<p itemprop="name" class="contacts__name">{{ employee.title }}</p>
						<div v-html="employee.content"></div>
						<!-- <a
							v-if="employee.phone"
							:href="`tel:${employee.phone}`"
							class="contacts__link link"
							@click="phoneClick"
						>
							<span class="icon icon--phone"></span>
							{{ employee.phone }}
						</a>
						<a
							v-if="employee.mail"
							:href="`mailto:${employee.mail}`"
							class="contacts__link link"
						>
							<span class="icon icon--mail"></span>
							{{ employee.mail }}
						</a>-->
					</li>
				</ul>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'contactssList',

	props: {
		data: {
			type: Array,
			required: true,
		},
	},

	methods: {
		phoneClick() {
			/*  */
		},
	},
}
</script>
