var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main"},[(_vm.blocks)?_c('section',{staticClass:"main__box"},[_vm._m(0)]):_vm._e(),(_vm.blocks)?_c('div',{staticClass:"bg bg--gray"},[_c('section',{staticClass:"main__box main__box--big"},[_c('div',{staticClass:"container container--small"},[_c('div',{staticClass:"\n\t\t\t\t\t\tmain__text main__text--big main__text--center\n\t\t\t\t\t\ttext-normal\n\t\t\t\t\t\teditor\n\t\t\t\t\t\tlink-no-underline\n\t\t\t\t\t",domProps:{"innerHTML":_vm._s(_vm.blocks[6].content)}})])])]):_vm._e(),(_vm.blocks)?_c('section',{staticClass:"main__box"},[_c('div',{staticClass:"container container--small"},[_c('ContactsList',{attrs:{"data":[
					{
						title: 'По техническим вопросам работы карты',
						list: [_vm.blocks[2], _vm.blocks[3]],
					},
					{
						title: 'Отдел взаимодействия с ТСП',
						list: [_vm.blocks[0], _vm.blocks[1]],
					},
					{
						title: 'Взаимодействие со СМИ',
						list: [_vm.blocks[4], _vm.blocks[5]],
					} ]}})],1)]):_vm._e(),_c('section',{staticClass:"main__box"},[_c('div',{staticClass:"container"},[_c('Map',{attrs:{"data":{
					icon: require('@/assets/images/icons/map_type-3.svg'),
					singlePoint: _vm.mapInfo,
					title: 'Офисы оператора Сапфир эксперт',
				}}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container container--small"},[_c('h1',{staticClass:"main__title"},[_vm._v("Контактная информация")])])}]

export { render, staticRenderFns }