<template>
	<main class="main">
		<section class="main__box" v-if="blocks">
			<div class="container container--small">
				<h1 class="main__title">Контактная информация</h1>
			</div>
		</section>
		<div class="bg bg--gray" v-if="blocks">
			<section class="main__box main__box--big">
				<div class="container container--small">
					<div
						class="
							main__text main__text--big main__text--center
							text-normal
							editor
							link-no-underline
						"
						v-html="blocks[6].content"
					></div>
				</div>
			</section>
		</div>
		<section class="main__box" v-if="blocks">
			<div class="container container--small">
				<ContactsList
					:data="[
						{
							title: 'По техническим вопросам работы карты',
							list: [blocks[2], blocks[3]],
						},
						{
							title: 'Отдел взаимодействия с ТСП',
							list: [blocks[0], blocks[1]],
						},
						{
							title: 'Взаимодействие со СМИ',
							list: [blocks[4], blocks[5]],
						},
					]"
				></ContactsList>
			</div>
		</section>

		<section class="main__box">
			<div class="container">
				<Map
					:data="{
						icon: require('@/assets/images/icons/map_type-3.svg'),
						singlePoint: mapInfo,
						title: 'Офисы оператора Сапфир эксперт',
					}"
				></Map>
			</div>
		</section>
	</main>
</template>

<script>
import ContactsList from '@/components/ContactsList.vue'
//import Map from '@/components/Map.vue'

export default {
	name: 'Contacts',

	components: {
		ContactsList,
		Map,
	},

	data() {
		return {
			contacts: null,
			mapInfo: [
				{
					id: 1,
					name: 'САПФИР-ЭКСПЕРТ',
					geolocation: [56.830901, 60.61041],
					address: '620075, г. Екатеринбург, ул. Гоголя д. 36, оф. 402',
					phone: '8 (343) 379 08 86',
					mail: 'info@eskso.ru',
					operatingMode: 'ПН-ПТ: С 9:00 до 18:00',
				},
			],
		}
	},

	computed: {
		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},
	},

	mounted() {
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})
		this.contacts = [
			{
				title: 'Отдел взаимодействия с ТСП',
				list: [
					{
						name: 'Юрлова Антонина Альбертовна',
						phone: '8 (343) 379 08 86 (вн. 110)',
						mail: 'yurlova_aa@eskso.ru',
					},
					{
						name: 'Трошкин Андрей Арнольдович',
						phone: '8 (343) 379 08 86 (вн. 111)',
						mail: 'troshkin_aa@eskso.ru',
					},
				],
			},
			{
				title: 'Отдел взаимодействия с Банками',
				list: [
					{
						name: 'Пузырева Наталья Геннадьевна',
						phone: '8 (343) 379 08 86 (вн. 120)',
						mail: 'ng@eskso.ru',
					},
					{
						name: 'Воробьева Ольга Николаевна',
						phone: '8 (343) 379 08 86 (вн. 121)',
						mail: 'vorobeva_on@eskso.ru',
					},
				],
			},
			{
				title: 'Взаимодействие со СМИ',
				list: [
					{
						name: 'Пузырева Наталья Геннадьевна',
						phone: '8 (343) 379 08 86 (вн. 120)',
						mail: 'ng@eskso.ru',
					},
					{
						name: 'Воробьева Ольга Николаевна',
						phone: '8 (343) 379 08 86 (вн. 121)',
						mail: 'vorobeva_on@eskso.ru',
					},
				],
			},
		]
	},
}
</script>
